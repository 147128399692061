import { useState, useEffect, useReducer } from "react";
import is from "is_js";

import Modal from "../UI/Modal/Modal";
import Select from "../UI/Select/Select";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import Space from "../UI/Space/Space";

import useInput from "../../hooks/useInput";
import { getLocations } from "../../api/api";

import classes from "./Users.module.scss";

const initialLocationState = {
  options: [],
  value: "",
  hasError: false,
};

const locationReducer = (state, action) => {
  if (action.type === "SET_OPTIONS") {
    return {
      options: action.payload,
      value: state.value,
      hasError: state.hasError,
    };
  }

  if (action.type === "CHANGE") {
    return {
      options: state.options,
      value: action.payload,
      hasError: action.payload === "",
    };
  }

  if (action.type === "SET_VALID") {
    return {
      options: state.options,
      value: state.value,
      hasError: action.payload,
    };
  }

  return initialLocationState;
};

/**
 * 
 * @param {{onSave: (body: import("../../api/users").UpdateUserRequestBody), onClose: () => void}} param0 
 * @returns 
 */
const InviteUserModal = ({ onClose, onSave }) => {
  const [locationState, dispatchLocation] = useReducer(
    locationReducer,
    initialLocationState
  );

  const { value: locationStateValue } = locationState;

  /**
   * First Name
   */
  const {
    value: name,
    isTouched: nameTouched,
    isValid: nameIsValid,
    hasError: nameHasError,
    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInput((value) => value.trim() !== "");

  /**
   * Email
   */
  const {
    value: email,
    isTouched: emailTouched,
    isValid: emailIsValid,
    hasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => is.email(value));

  /**
   * Phone number
   */
  const {
    value: phoneNumber,
    isTouched: phoneNumberTouched,
    isValid: phoneNumberIsValid,
    hasError: phoneNumberHasError,
    inputChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
  } = useInput((value) => value.trim().length === 10);

  const [formIsValid, setformIsValid] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  /**
   * Location
   */
  useEffect(() => {
    getLocations()
      .then((location) => {
        if (location.length > 1) {
          const optionsArr = location.map((item) => {
            return {
              value: item.id,
              label: item.name,
              isDisabled: false,
            };
          });

          optionsArr.unshift({
            value: "0",
            label: "Select...",
            isDisabled: true,
          });

          dispatchLocation({ type: "SET_OPTIONS", payload: optionsArr });
          dispatchLocation({ type: "CHANGE", payload: "0" });
        } else {
          dispatchLocation({
            type: "SET_OPTIONS",
            payload: [
              {
                value: location[0].id,
                label: location[0].name,
                isDisabled: false,
              },
            ],
          });
          dispatchLocation({ type: "CHANGE", payload: location[0].id });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const changeLocationHandler = (event) => {
    dispatchLocation({ type: "CHANGE", payload: event.target.value });
  };


  /**
   * Validation
   */
  useEffect(() => {
    const isValid =
      locationStateValue &&
      locationStateValue !== "0" &&
      nameIsValid &&
      emailIsValid &&
      phoneNumberIsValid;

    if (isValid) {
      setformIsValid(true);
    } else {
      setformIsValid(false);
    }
  }, [
    locationStateValue,
    nameIsValid,
    emailIsValid,
    phoneNumberIsValid,
  ]);

  const saveHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      if (!locationStateValue || locationStateValue === "0") {
        dispatchLocation({ type: "SET_VALID", payload: true });
      }

      if (!nameIsValid) {
        nameBlurHandler();
      }

      // email
      if (!emailIsValid) {
        emailBlurHandler();
      }

      if (!phoneNumberIsValid) {
        phoneNumberBlurHandler();
      }

      return;
    }

    const prepareBody = {
      name: `${name}`,
      email: `${email}`,
      location_id: `${locationStateValue}`,
      phone_number: `${phoneNumber}`,
    };

    setIsProcessing(true)
    onSave(prepareBody)
    setTimeout(() => setIsProcessing(false), 4000)
  };

  return (
    <Modal title="Invite New Coordinator" onClose={onClose}>
      <form onSubmit={saveHandler}>
        <Select
          label="Location:*"
          options={locationState.options}
          clsType="small"
          disabled={locationState.options.length === 1 && true}
          value={locationState.value}
          hasError={locationState.hasError}
          onChange={changeLocationHandler}
          errorMessage="Select Location"
        />

        <Space value="20" />

        <Input
          label="Name:*"
          clsType="small"
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
          value={name}
          touched={nameTouched}
          hasError={nameHasError}
        />

        <Space value="20" />

        <Input
          type="email"
          label="Email:*"
          clsType="small"
          value={email}
          touched={emailTouched}
          hasError={emailHasError}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
        />

        <Input
          type="number"
          label="Phone:*"
          clsType="small"
          onChange={phoneNumberChangeHandler}
          onBlur={phoneNumberBlurHandler}
          value={phoneNumber}
          touched={phoneNumberTouched}
          hasError={phoneNumberHasError}
        />

        <Space value="30" />

        <div className={classes.Modal__center}>
          <Button color="primary" type="submit"
            disabled={isProcessing}>
            Send Invitation
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InviteUserModal;
