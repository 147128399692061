import imageCompression from 'browser-image-compression';
import { getHeaders, getBaseUrl } from './common.ts';

const BASE_URL = getBaseUrl();

/**
 * Get activity
 * @param {*} page - (Integer),  the page number, starting with 1, default is 1
 * @param {*} perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * @returns - Returns most recent activity for the organization or location
 */
export const getActivity = async (page = 1, perPage = 6) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/activity?page=${page}&perPage=${perPage}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get CBO
 * @returns - Returns cbo-level information, like helpful information and important links.
 */
export const getCbo = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/cbo`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Gets any users who are valid targets to have a file sent to them
 * @returns - a list of objects with a name and an id
 */
export const getSendableUsers = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/file/users`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Gets any users who are valid targets to have a file sent to them
 * @returns - a list of objects with a name and an id
 */
export const getStatusCodes = async () => {
  const options = {
    headers: await getHeaders(),
  };

  if(window.sessionStorage.getItem('status_codes')) {
    return JSON.parse(window.sessionStorage.getItem('status_codes'))
  }

  const url = `${BASE_URL}/file/status_codes`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  const status_codes = await resp.json();

  window.sessionStorage.setItem('status_codes', JSON.stringify(status_codes));

  return status_codes;
};

/**
 * Gets any users who are valid targets to have a file sent to them
 * @returns - a list of objects with a name and an id
 */
export const postStatusChange = async (file_id, status, comment) => {
  const options = {
    headers: await getHeaders(),
    method: 'POST',
    body: JSON.stringify({
      file_id,
      status,
      comment
    })
  };

  const url = `${BASE_URL}/file/status`;
  await fetch(url, options);
};



/**
 * Get Enrollments
 * @param {*} params: {
 * perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * page - (Integer), he page number, starting with 1, default is 1
 * location_id - (String), only include results from this provided location id
 * first - (String), First name of the enrollee
 * last - (String), Last name of the enrollee
 * id - (String), id for the enrollment
 * dob - (Date), Date of birth in the format YYYY-MM-DD
 * start_date - (Date), Earliest date to include in the format YYYY-MM-DD
 * end_date - (Date), Latest date to include in the format YYYY-MM-DD. This date assumes end of day for this date.
 * sortBy - (String), Field the data should be sorted by
 * sortDirection - (String), Direction the data should be sorted by
 * }
 *
 * @returns - Returns enrollments the user has access to
 */
export const getEnrollments = async (params) => {
  const perPage = params && params.perPage ? params.perPage : 6;
  const page = params && params.page ? params.page : 1;
  const location_id =
    params && params.location_id ? `&location_id=${params.location_id}` : "";
  const first = params && params.first ? `&first=${params.first}` : "";
  const last = params && params.last ? `&last=${params.last}` : "";
  const id = params && params.id ? `&id=${params.id}` : "";
  const dob = params && params.dob ? `&dob=${params.dob}` : "";
  const start_date =
    params && params.start_date ? `&start_date=${params.start_date}` : "";
  const end_date =
    params && params.end_date ? `&end_date=${params.end_date}` : "";
  const sortBy = params && params.sortBy ? `&sortBy=${params.sortBy}` : "";
  const sortDirection =
    params && params.sortDirection
      ? `&sortDirection=${params.sortDirection}`
      : "";

  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/enrollments?page=${page}&perPage=${perPage}${location_id}${first}${last}${id}${dob}${start_date}${end_date}${sortBy}${sortDirection}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Enrollment by Id
 * @param {*} id - (Srting), id of the enrollment
 * @returns
 */
export const getEnrollmentsId = async (id) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/enrollment/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Enrollments Export
 * @param {*} params: {
 * perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * page - (Integer), he page number, starting with 1, default is 1
 * location_id - (String), only include results from this provided location id
 * first - (String), First name of the enrollee
 * last - (String), Last name of the enrollee
 * id - (String), id for the enrollment
 * dob - (Date), Date of birth in the format YYYY-MM-DD
 * sortBy - (String), Field the data should be sorted by
 * sortDirection - (String), Direction the data should be sorted by
 * }
 *
 * @returns - Returns enrollments the user has access to
 */
export const getEnrollmentsExport = async (params) => {

  console.log(params)
  const locationId = params && params.locationId ? `&locationId=${params.locationId}` : "";
  const location_id = params && params.location_id ? `&location_id=${params.location_id}` : "";
  const cbo_id = params && params.cbo_id ? `&cbo_id=${params.cbo_id}` : "";
  const first = params && params.first ? `&first=${params.first}` : "";
  const last = params && params.last ? `&last=${params.last}` : "";
  const id = params && params.id ? `&id=${params.id}` : "";
  const dob = params && params.dob ? `&dob=${params.dob}` : "";
  const start_date = params && params.start_date ? `&start_date=${params.start_date}` : "";
  const end_date = params && params.end_date ? `&end_date=${params.end_date}` : "";
  const zip = params && params.zip ? `&zip=${params.zip}` : "";
  const email = params && params.email ? `&email=${params.email}` : "";
  const status = params && params.status ? `&status=${params.status}` : params && params.excludeFailures === true ? `&status[]=Accepted&status[]=Itin&status[]=New&status[]=Override&status[]=Submitted&status[]=Withdraw` : "";
  const address_1 = params && params.address_1 ? `&address_1=${params.address_1}` : "";
  const address_2 = params && params.address_2 ? `&address_2=${params.address_2}` : "";
  const city = params && params.city ? `&city=${params.city}` : "";
  const state = params && params.state ? `&state=${params.state}` : "";
  const alternate_email = params && params.alternate_email ? `&alternate_email=${params.alternate_email}` : "";
  const physical_address_line1 = params && params.physical_address_line1 ? `&physical_address_line1=${params.physical_address_line1}` : "";
  const physical_address_line2 = params && params.physical_address_line2 ? `&physical_address_line2=${params.physical_address_line2}` : "";
  const physical_city = params && params.physical_city ? `&physical_city=${params.physical_city}` : "";
  const physical_state = params && params.physical_state ? `&physical_state=${params.physical_state}` : "";
  const physical_zip = params && params.physical_zip ? `&physical_zip=${params.physical_zip}` : "";
  const work_phone = params && params.work_phone ? `&work_phone=${params.work_phone}` : "";
  const cell_phone = params && params.cell_phone ? `&cell_phone=${params.cell_phone}` : "";
  const home_phone = params && params.home_phone ? `&home_phone=${params.home_phone}` : "";
  const routing_number = params && params.routing_number ? `&routing_number=${params.routing_number}` : "";
  const account_number = params && params.account_number ? `&account_number=${params.account_number}` : "";
  const user = params && params.user ? `&user=${params.user}` : "";
  const card_type = params && params.card_type ? `&card_type=${params.card_type}` : "";
  const identification_type = params && params.identification_type ? `&identification_type=${params.identification_type}` : "";
  //const excludeFailures = params && params.excludeFailures === true ? `
  const sortBy = params && params.sortBy ? `&sortBy=${params.sortBy}` : "";
  const sortDirection = params && params.sortDirection ? `&sortDirection=${params.sortDirection}` : "";

  // much needed refactor
  // refactor koken
  /*
  const queryParams = new URLSearchParams();
  for (const key of [
    "location_id",
    "first",
    "last",
    "id",
    "dob",
    "start_date",
    "end_date",
    "sortBy",
    "sortDirection",
    "cbo_id"
  ]) {
    if (params && params[key]) {
      queryParams.append(key, params[key]);
    }
  }
  */

  const options = {
    headers: await getHeaders(),
  };

//  const url = `${BASE_URL}/enrollments/export?${queryParams.toString()}`;

  const url = `${BASE_URL}/enrollments/export?${location_id}${first}${last}${id}${dob}${start_date}${end_date}${locationId}${cbo_id}${zip}${email}${status}${address_1}${address_2}${city}${state}${sortBy}${sortDirection}${alternate_email}${physical_address_line1}${physical_address_line2}${physical_city}${physical_state}${physical_zip}${work_phone}${cell_phone}${home_phone}${routing_number}${account_number}${user}${card_type}${identification_type}`;

  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Create Enrollments
 * @param {*} body
 * @returns Submit a debit card enrollment request to the card processor
 */
export const createEnrollments = async (body) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify(body),
  };

  const url = `${BASE_URL}/enrollments`;
  const resp = await fetch(url, options).then(res => res.json().then(json => {
    if (res.status === 403) {
      if (json.reason.enrollments_disabled) {
        return Object.assign(json, { action: 'redirect' })
      } else {
        return Object.assign(json, { action: 'failure' })
      }
    } else {
      return Object.assign(json, { action: 'continue' })
    }
  }));

  // response has an ID property ONLY when successful.
  // there has been a bug where responses are flagged as successful when they shouldnt be.
  //
  // this should fix the issue temporarily until we can find out what's causing failures
  // to have a 200 status code.
  const hasId = resp.hasOwnProperty('id');
  if(!hasId) {
    resp.action = 'failure';
  }

  return Object.assign({req_successful: resp.status !== 500 && hasId}, resp);
};

/**
 * Execute an action on all cbos in list
 * @param {string} action CBO Action
 * @param {string[]} ids A list of all CBOIds to execute the action on
 */
export const executeCBOBulkAction = async (action, ids) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({cbos: ids, action}),
  };

  const url = `${BASE_URL}/org/cbo/bulk`;
  const resp = await fetch(url, options).then(res => res.json().then(json => {
    json.statusCode = res.status;
    return json;
  }));

  return resp;
}

export const getEnrollStatus = async () => {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };
  const url = `${BASE_URL}/enrollments/status`;

  const status = await fetch(url, options);
  try {
    if ((await status.json()).enabled === true) {
      return true;
    }
  } catch {

  }


  return false;
}

export const getFullEnrollmentStatus = async () => {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };
  const url = `${BASE_URL}/enrollments/status`;

  const status = await fetch(url, options);


  return status.json();
}

export const getEnrollmentStatuses = async () => {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };
  const url = `${BASE_URL}/enrollments/statuses`;

  const statuses = await fetch(url, options);
  return await statuses.json();
}

export const cancelEnrollments = async (body) => {
  const options = {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify(body)
  };
  const url = `${BASE_URL}/enrollments/cancel`;

  const result = await fetch(url, options)
  return await result.json()
}

export const listCBOsForAdmin = async (searchConditions) => {
  const params = new URLSearchParams();

  for(const key in searchConditions) {
    params.set(key, searchConditions[key]);
  }

  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/cbos/list?${params.toString()}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong! probably an incorrect date.");
  }

  return await resp.json();
}

export const getCBOAdmin = async (cbo_id) => {
  const params = new URLSearchParams();

  params.set('cboId', cbo_id);

  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/org/cbo?${params.toString()}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

export const updateCBOAdmin = async (cboId, data) => {
  if(!cboId) {
    throw new Error("cbo id not found!");
  }
  const options = {
    headers: await getHeaders(),
    method: 'PUT',
    body: JSON.stringify(Object.assign({cboId}, data))
  };

  const url = `${BASE_URL}/org/cbo`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  // return await resp.json();
}

export const createCBOAdmin = async (data) => {
  const options = {
    headers: await getHeaders(),
    method: 'POST',
    body: JSON.stringify(Object.assign(data))
  };

  const url = `${BASE_URL}/org/cbo`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

/**
 *
 * @param {number} cboId
 * @returns {Promise<{success: boolean, message?: string}>} result
 */
export const deactivateCBOAdmin = async (cboId) => {
  const options = {
    headers: await getHeaders(),
    method: 'DELETE',
  };

  const url = `${BASE_URL}/org/cbo/${cboId}`;

  const resp = await fetch(url, options).then(res => res.json().then(json => {
    if(res.status !== 200) {
      return {success: false, message: json.message};
    }else {
      return {success: true}
    }
  }));

  // if (!resp.ok) {
  //   throw new Error("Something went wrong!");
  // }

  return resp;
}

/* Admin Location CRUD */

const ADMIN_LOCATION_BASE = `${BASE_URL}/org/location`

export const getLocaitonAdmin = async (location_id) => {
  const params = new URLSearchParams();

  params.set('locationId', location_id);

  const options = {
    headers: await getHeaders(),
  };

  const url = `${ADMIN_LOCATION_BASE}?${params.toString()}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

export const updateLocationAdmin = async (location_id, data) => {
  if(!location_id) {
    throw new Error("location id not found!");
  }
  const options = {
    headers: await getHeaders(),
    method: 'PUT',
    body: JSON.stringify(Object.assign({location_id}, data))
  };

  const resp = await fetch(ADMIN_LOCATION_BASE, options);


  if (!resp.ok) {
    const result = await resp.json()
    if (result && result.message) {
      throw new Error(result.message);
    } else {
      throw new Error("Something went wrong!");
    }
  }

  return await resp.json();
}

export const createLocationAdmin = async (data) => {
  const options = {
    headers: await getHeaders(),
    method: 'POST',
    body: JSON.stringify(Object.assign(data))
  };

  const resp = await fetch(ADMIN_LOCATION_BASE, options);

  if (!resp.ok) {
    const result = await resp.json()
    if (result && result.message) {
      throw new Error(result.message);
    } else {
      throw new Error("Something went wrong!");
    }
  }

  return await resp.json();
}

/**
 *
 * @param {number} cboId
 * @returns {Promise<{success: boolean, message?: string}>} result
 */
export const deactivateLocationAdmin = async (cboId) => {
  const options = {
    headers: await getHeaders(),
    method: 'DELETE',
  };

  const url = `${BASE_URL}/org/cbo/${cboId}`;

  const resp = await fetch(url, options).then(res => res.json().then(json => {
    if(res.status !== 200) {
      return {success: false, message: json.message};
    }else {
      return {success: true}
    }
  }));

  // if (!resp.ok) {
  //   throw new Error("Something went wrong!");
  // }

  return resp;
}


export const getOrgInfo = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/org/info`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

export const getMessageUpdateDate = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/org/message`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

export const runEnrollmentReport = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/reports/enrollmentlimit`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return (await resp.json()).report;
}

export const getTables = async (database) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/data/database/tables?db=${database}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  const json = await resp.json();

  return json.tables;
}

export const getSavedQueries = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/data/query/saved`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  const json = await resp.json();

  return json.saved;
}

export const runQuery = async (query, db) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({
      query,
      db
    }),
  };

  const url = `${BASE_URL}/data/database/query`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    var error_text =
      "Sorry, we were unable to complete that request.  Please check your inputs and try again.";
    try {
      var json = await resp.json();
      error_text = json.message;
    } catch { }
    throw new Error(error_text);
  }

  return await resp.json();
};

export const saveQuery = async (sql_query, name, database) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({
      sql_query,
      name,
      database
    }),
  };

  const url = `${BASE_URL}/data/query/saved`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    var error_text =
      "Sorry, we were unable to complete that request.  Please check your inputs and try again.";
    try {
      var json = await resp.json();
      error_text = json.message;
    } catch { }
    throw new Error(error_text);
  }

  return await resp.json();
};

export const getCardStatistics = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/card/usagestatistics`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

export const getTransactionReport = async (chart) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/org/transactions?category=${chart}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

export const getEnrollmentsByLocation = async (from, to) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/org/enrollments/simplified?to_date=${to.valueOf()}&from_date=${from.valueOf()}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return (await resp.json()).enrollments;
}

export const setOrgMessage = async (message) => {
  const options = {
    headers: await getHeaders(),
    method: 'POST',
    body: JSON.stringify({
      message
    })
  };

  const url = `${BASE_URL}/org/message`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }
}

/**
 * Get Locations
 * @returns Returns locations associated with the user
 */
export const getLocations = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/locations`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Location by Id
 * @param {*} id - (String), id of the location
 * @returns
 */
export const getLocationsId = async (id) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/org/location/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Locations Simplified
 * @returns Returns simplified location objects associated with the user
 */
export const getLocationsSimplified = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/locations/simplified`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

/**
 * Get Locations Export
 */
export const getLocationsExport = async (params) => {
  const id = params && params.id ? `&id=${params.id}` : "";
  const name = params && params.name ? `&name=${params.name}` : "";
  const cbo_id = params && params.cbo_id ? `&cbo_id=${params.cbo_id}` : "";
  const phone = params && params.phone ? `&phone=${params.phone}` : "";
  const inactive = params && params.inactive === false ? `&inactive=${params.inactive}` : "";
  const address_1 = params && params.address_1 ? `&address_1=${params.address_1}` : "";
  const address_2 = params && params.address_2 ? `&address_2=${params.address_2}` : "";
  const city = params && params.city ? `&city=${params.city}` : "";
  const state = params && params.state ? `&state=${params.state}` : "";
  const zip = params && params.zip ? `&zip=${params.zip}` : "";
  const minEnrollmentsYtd = params && params.minEnrollmentsYtd ? `&minEnrollmentsYtd=${params.minEnrollmentsYtd}` : "";
  const maxEnrollmentsYtd = params && params.maxEnrollmentsYtd ? `&maxEnrollmentsYtd=${params.maxEnrollmentsYtd}` : "";
  const lastEntryStart = params && params.lastEntryStart ? `&lastEntryStart=${params.lastEntryStart}` : "";
  const lastEntryEnd = params && params.lastEntryEnd ? `&lastEntryEnd=${params.lastEntryEnd}` : "";
  const primary_contact_id = params && params.primary_contact_id ? `&primary_contact_id=${params.primary_contact_id}` : "";
  const secondary_contact_id = params && params.secondary_contact_id ? `&secondary_contact_id=${params.secondary_contact_id}` : "";

  const options = {
    headers: await getHeaders()
  }

  const url = `${BASE_URL}/locations/export?${id}${name}${cbo_id}${phone}${inactive}${city}${state}${zip}${minEnrollmentsYtd}${maxEnrollmentsYtd}${lastEntryStart}${lastEntryEnd}${primary_contact_id}${secondary_contact_id}${address_1}${address_2}`
  const resp = await fetch(url, options);
  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}

/**
 * @param cbo_id the CBO id related to location
 * @returns
 */
export const getLocationsFromCBO = async (cbo_id) => {
  if (!cbo_id) {
    return null;
  }
  const options = {
    headers: await getHeaders()
  };

  const url = `${BASE_URL}/cbo/${cbo_id}/locations`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get CBOs
 * @returns Returns all CBOs
 */
export const getCBOs = async (status = 'all') => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/cbos/${status}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Update Location
 * @param {*} id
 * @param {*} body : {
 * name - (String)
 * address - (String)
 * address_2 - (String)
 * city - (String)
 * state - (String)
 * zip - (Number) - length = 5
 * phone - (String)
 * }
 * @returns
 */
export const updateLocation = async (id, body) => {
  const options = {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify({
      name: body.name,
      address: {
        address: body.address,
        address_2: body.address_2,
        city: body.city,
        state: body.state,
        zip: body.zip,
      },
      phone: body.phone,
    }),
  };

  const url = `${BASE_URL}/location/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    var error_text =
      "Sorry, we were unable to complete that request.  Please check your inputs and try again.";
    try {
      var json = await resp.json();
      error_text = json.message;
    } catch { }
    throw new Error(error_text);
  }

  return await resp.json();
};

/**
 * Deactivate Locations
 */
export const deactivateLocations = async (body) => {
  const options = {
    method: "DELETE",
    headers: await getHeaders(),
    body: JSON.stringify(body)
  };
  const url = `${BASE_URL}/locations`;

  const result = await fetch(url, options)
  .then((resp) => {
    if (!resp.ok || resp.status === 500) {
      console.log('resp not ok')
      try {
        return resp.json().then(j => {
          return { status: 'failure', response: j.response }
        });
      } catch {
        return { status: 'failure', response: { message: 'Error sending. Please check your inputs and try again.' } }
      }
    } else {
      return { status: 'success' }
    }
  })
  return result;
}

/**
 * Get users
 * @param {*} page - (Integer),  the page number, starting with 1, default is 1
 * @param {*} perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * @returns - Returns a list of users if the user is a super coordinator
 */
export const getUsers = async (params = {}) => {
  const query = new URLSearchParams();

  if (params) {
    query.set('page', params.page || 1)
    query.set('perPage', params.perPage || 6)
    query.set('include_deactivated', params.include_deactivated || false)
    query.set('include_legacy', params.include_legacy || false)
    if (params.cbo_id) {
      query.set('cbo_id', params.cbo_id)
    }
    query.set('order_by', params.order_by || 'name')
    query.set('order_direction', params.order_direction || 'ASC')

  }

  const options = {
    headers: await getHeaders(),
  };
  const url = `${BASE_URL}/users?${query.toString()}`;
  //  console.log("gets here: " + url)
  try {
    const resp = await fetch(url, options);

    //    console.log("gets here too")

    if (!resp.ok) {
      throw new Error("Something went wrong!");
    }

    return resp.json();
  } catch (e) {
    console.error(e)
  }
  return null;
};

export const getUserProfilePicture = async (username) => {
  if (!window.pfp) {
    window.pfp = {};
  }
  if (!window.pfp[username]) {
    window.pfp[username] = 'empty';
  } else if (window.pfp !== 'empty') {
    return window.pfp[username];
  }

  const options = {
    headers: await getHeaders(),
  };



  const url = `${BASE_URL}/user/${username}`;

  const resp = await fetch(url, options);

  if (resp.status !== 200) {
    throw new Error(resp);
  }


  const picture = (await resp.json()).picture;

  window.pfp[username] = picture;

  if (picture == null) {
    return null;
  }

  return picture.replace('redesign.', 'new.');
};

/**
 * Update User
 * @param {*} id
 * @param {*} body : {
 * first - (String)
 * last - (String)
 * phone_number - (String) - length = 10
 * phone_type - (String)
 * }
 * @returns Update some user details
 */
export const updateUser = async (id, body) => {
  const options = {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify({
      name: body.first || body.name,
      phone: {
        phone_number: body.phone_number,
        phone_type: body.phone_type,
      },
      email: body.email,
      upload_enabled: body.upload_enabled,
      location_id: body.location_id,
      cbo_id: body.cbo_id,
      role: body.role,
      location_contact: body.location_contact,
    }),
  };



  const url = `${BASE_URL}/user/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp;
};


export const getRoles = async (safeMode = false) => {
  if (window.rolesarefound === 1) {
    return await (async () => {
      while (!window.roles)
        await new Promise(resolve => setTimeout(resolve, 100));
      // console.log('me located')
      return window.roles
    })();
  }

  window.rolesarefound = 1;

  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/me/roles?${safeMode ? 'safeMode=true' : ''}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  try {
    window.roles = (await resp.json()).map(r => r.name);

    return window.roles;
  }catch(e) {
    const respText = await resp.text();

    return {cause: respText.includes('429') ? 'Rate Limit' : 'Unknown'}
  }
  
}

/**
 * Get Me
 * @returns Returns profile data for the current user
 */
export const getMe = async () => {
  if (window.meIsFound === 1) {
    return await (async () => {
      while (!window.me)
        await new Promise(resolve => setTimeout(resolve, 100));
      // console.log('me located')
      return window.me
    })();
  }

  window.meIsFound = 1;

  // console.log('fetching me from database')

  const options = {
    headers: await getHeaders(),
  };
  const thisUrl = window.location.toString().split('?');

  const url = `${BASE_URL}/me`;
  let resp;

  let responseBody = null;

  try {
    resp = await fetch(url, options);
  } catch (e) {
    console.log(e)
  }
  if (!resp.ok) {
    console.log(resp)

    try {
      const json = await resp.json();
      responseBody = json;

      if ((json.cause === 'User is deactivated' || json.cause === 'User Does not exist') && !thisUrl[0].endsWith('/notexist')) {
        window.location = '/notexist';
        return;
      }

      // hope it was an auth0 rate limit, and try again
      if((json.cause === "getting user roles was unsuccessful")) {
        resp = await fetch(url, options);

        if(!resp.ok) {
          console.log(resp)
        }
      }

    } catch { }

    if (thisUrl[0].endsWith('/notexist')) {
      return { roles: await getRoles(true) };
    }
  }
  
  const me = responseBody || await resp.json();

  me.phone.phone_number = me.phone.phone_number.trim()

  // console.log('set win me')
  window.me = me;
  console.log(me)

  return responseBody;

};



/**
 * Update Me
 * @param {*} body : {
 * first - (String)
 * last - (String)
 * email - (String)
 * phone_number - (String) - length = 10
 * phone_type - (String)
 * }
 * @returns Update some user details
 */
export const updateMe = async (body) => {
  const options = {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify({
      name: body.name,
      phone_number: body.phone_number,
      email: body.email,
    }),
  };

  const url = `${BASE_URL}/me`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * updatePassword
 * @param {*} body : {
 * first - (String)
 * last - (String)
 * email - (String)
 * phone_number - (String) - length = 10
 * phone_type - (String)
 * }
 * @returns Update some user details
 */
export const updatePassword = async (body) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({
      action: "resetPassword"
    })
  };

  const url = `${BASE_URL}/me`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * @param {*} id
 * @param {*} action - (String)
 *
 * @returns Performs an operation for a user
 */
export const userActions = async (id, action) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({
      action: action,
    }),
  };

  const url = `${BASE_URL}/user/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * User Invitation
 * @param {*} body
 * @returns - Sends an invitation to a user to become a coordinator
 */
export const userInvitation = async (body) => {
  if ((!body.location_id || body.location_id === 'null') && !body.hasOwnProperty('cbo_id')) {
    throw new Error("Location ID not specified. body was: " + JSON.stringify(body))
  }

  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify(body),
  };

  const url = `${BASE_URL}/users`;
  console.log('fetching')
  return await fetch(url, options).then((resp) => {
    if (!resp.ok || resp.status === 500) {
      console.log('resp not ok')
      try {
        return resp.json().then(j => {
          return { status: 'failure', response: j.response }
        });
      } catch {
        return { status: 'failure', response: { message: 'Error sending. Please check your inputs and try again.' } }
      }
    } else {
      return { status: 'success' }
    }
  })
};

/**
 * Get Files
 * @param {*} page - (Integer),  the page number, starting with 1, default is 1
 * @param {*} perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * @returns - Returns a list of all files for this user with most recent first
 */
export const getFiles = async (page = 1, perPage = 6) => {
  const options = {
    headers: await getHeaders(),
    'Content-Type': 'application/json'
  };

  const url = `${BASE_URL}/files?page=${page}&perPage=${perPage}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Files
 * @param {*} page - (Integer),  the page number, starting with 1, default is 1
 * @param {*} perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * @returns - Returns a list of all files for this user with most recent first
 */
export const getOpenFiles = async (page = 1, perPage = 6) => {
  const options = {
    headers: await getHeaders(),
    'Content-Type': 'application/json'
  };

  const url = `${BASE_URL}/open/files?page=${page}&perPage=${perPage}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Shared Files
 * @param {*} page - (Integer),  the page number, starting with 1, default is 1
 * @param {*} perPage - (Integer), number of items to return per page, sending -1 returns all at once. the default is 25 records.
 * @returns - Returns a list of all files for this user with most recent first
 */
export const getSharedFiles = async (page = 1, perPage = 6) => {
  const options = {
    headers: await getHeaders(),
    'Content-Type': 'application/json'
  };

  const url = `${BASE_URL}/shared/files?page=${page}&perPage=${perPage}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get File
 * @param {*} id - (integer)
 * @returns
 */
export const getFile = async (id) => {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/file/${id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }
  const blob = await resp.blob();
  const loc = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  console.log(await resp.headers.get("File-Name"));
  a.setAttribute("href", loc);
  a.setAttribute("download", await resp.headers.get("File-Name"));
  a.click();
  return;
};

export const getLogs = async (userid, page, col, direction) => {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };

  const query = new URLSearchParams()

  if (col) {
    query.set('sort', col);
  }
  if (direction) {
    query.set('direction', direction)
  }
  query.set('page', page || 1)

  const url = `${BASE_URL}/user/file/${userid}?${query.toString()}`;
  const resp = await fetch(url, options);

  return resp.json();
};

/**
 * Delete File
 * @param {*} id - (integer)
 * @returns
 */
export const deleteFile = async (id) => {
  const options = {
    method: "DELETE",
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/file/${id}`;
  const resp = await fetch(url, options);

  return await resp.json();
};

/**
 * Upload File
 * @param {*} file
 * @param {*} fileName
 * @returns Uploads a new file for the current logged in user
 */
export const uploadFile = async (files, fileName, completedCallback, share_users=undefined) => {
  const max_size = 800000; // 800kb
  const file = files[0];

  console.log(files[0]);

  if (file.size > max_size) {
    alert("File is too large (max 800KB)");
    throw new Error("File too large to be securely uploaded");
  }

  const read = (callback) => {
    const reader = new FileReader();

    reader.onload = () => {
      callback(btoa(reader.result));
    };

    reader.readAsBinaryString(file);
  };

  return await read(async (data) => {
    if (data === false) {
      completedCallback(false);
      return;
    }

    const options = {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify({
        file: data,
        fileName: fileName,
        share_users: share_users
      }),
    };

    console.log(options);

    const url = `${BASE_URL}/files`;
    const resp = await fetch(url, options);

    if (!resp.ok) {
      throw new Error();
    }
    completedCallback(true);
    return await resp.json();
  });
};


/**
 * Shows a confirmation window, and if the user confirms, the page will be reloaded.
 * @param {string} message The confirmation message to be sent to the user
 */
const requestReload = (message) => {
  if (window.confirm(message)) {
    window.location.reload();
  }
}


/**
 * Upload File
 * @param {*} file
 * @returns Uploads a new file for the current logged in user
 * @see {@link uploadAndCompressProfile uploadAndCompressProfile()} for png and jpg files.
 */

export const uploadProfileWithoutCompression = async (file) => {
  console.log(file);
  const read = (callback) => {
    const reader = new FileReader();

    reader.onload = () => {
      callback(btoa(reader.result));
    };

    reader.readAsBinaryString(file);
  };

  return read(async (data) => {
    const options = {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify({
        base64: data,
        action: "updateProfile"
      }),
    };

    console.log(options);

    const url = `${BASE_URL}/me`;
    const resp = await fetch(url, options);

    if (!resp.ok) {
      throw new Error("Something went wrong!");
    }

    requestReload('To get an updated profile picture, the page must be refreshed. Reload now?');

    return await resp.json();
  });
};

/**
 * uploads profile picture to api, while also compressing it to save on space. Then reloads the page after it's done
 * @param {*} event Takes in an Input onChange Event
 * @returns nothing, and reloads the page.
 */
export const uploadAndCompressProfile = async (event) => {
  const imageFile = event.target.files[0];
  console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
  console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  const options = {
    maxSizeMB: 0.009,
    maxWidthOrHeight: 500,
    useWebWorker: true,
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);
    // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    if (!(compressedFile instanceof Blob)) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(compressedFile);
    reader.onloadend = async function () {
      const options = {
        method: "POST",
        headers: await getHeaders(),
        body: JSON.stringify({
          base64: reader.result.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''),
          action: "updateProfile"
        }),
      };

      console.log(options);

      const url = `${BASE_URL}/me`;
      const resp = await fetch(url, options);

      if (!resp.ok) {
        throw new Error("Something went wrong!");
      }

      requestReload('To get an updated profile picture, the page must be refreshed. Reload now?');

      // return await resp.json();
    }
  } catch (error) {
    console.log(error);
  }
}

// Notes
export const createNote = async (body) => {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify(body),
  };

  const url = `${BASE_URL}/notes`;
  const resp = await fetch(url, options)

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return Object.assign({req_successful: resp.status !== 500}, await resp.json());
};

export const getNotes = async (entity) => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/notes/${entity.type}/${entity.id}`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
};

/**
 * Get Cards
 * @returns Returns all card types, grouped by standard and instant issue
 */
export const getCardTypes = async () => {
  const options = {
    headers: await getHeaders(),
  };

  const url = `${BASE_URL}/cards/types`;
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return await resp.json();
}