import React, { useContext, useReducer, useState } from 'react';
import ReactDOM from "react-dom";
import {
  FilterItemBuilder,
  useFilters,
} from '../components/List/Common/DynamicFilters/FilterStateBuilder.ts';
import useInput from '../hooks/useInput.js';
import { getCBOs, userActions, userInvitation } from '../api/api.js';
import GridPageTemplate from '../components/Reports/GridPageTemplate.js';
import { Chip, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { fetchUsers, updateUser } from '../api/users.ts';
import { getAllLocations } from '../api/locations.ts';
import AdminInviteUserModal from '../components/Users/AdminInviteUserModal.js';
import useNotification from '../hooks/useNotification.js';
import Notification from '../components/UI/Notification/Notification.js';
import CardEnrollmentUserLevelContext from '../contexts/userLevelContext.js';
import { useNavigate } from 'react-router-dom';
import { useTrigger } from '../hooks/useTrigger.js';
import UserContextMenu from '../components/UsersAdmin/UserContextMenu.tsx';

const selectInitialState = () => {
  return { options: [], value: '' };
};

const selectReducer = (state, action) => {
  if (action.type === 'SET_OPTIONS') {
    return {
      options: action.payload,
      value: state.value,
    };
  }

  if (action.type === 'CHANGE') {
    return {
      options: state.options,
      value: action.payload,
    };
  }

  if (action.type === 'RESET') {
    return {
      options: state.options,
      value: '',
    };
  }

  return selectInitialState();
};

/**
 * @typedef {Object} User
 * @property {number} id - The unique identifier of the user.
 * @property {string} name - The name of the user. Sanitized to remove HTML tags if necessary.
 * @property {number} location_id - The identifier for the user's location.
 * @property {string} location_name - The name of the user's location.
 * @property {number|null} cbo_id - The identifier for the user's community-based organization, null if not applicable.
 * @property {string|null} cbo_name - The name of the user's community-based organization, null if not applicable.
 * @property {string} email - The email address of the user.
 * @property {string} status - The status of the user.
 * @property {string} phone_number - The phone number of the user.
 * @property {string} active_since - The epoch time since the user has been active, null if not available.
 * @property {string} last_login - The epoch time of the user's last login, null if not available.
 * @property {boolean} file_upload_allowed - Indicates whether the user is allowed to upload files.
 * @property {string} role - The user's role in terms of card enrollment level.
 * @property {number} location_cbo_id - The cbo_id of the location, not the user.
 */

function AdminUsersPage() {
  const role = useContext(CardEnrollmentUserLevelContext)
  const navigate = useNavigate();

  if (role !== 'Administrator') {
    navigate('/users')
  }


  const filters = useFilters(
    (filterValuesRef) => {
      return {
        last_activity_start: new FilterItemBuilder()
          .setLabel('Last Activity Start Date')
          .setParamName('last_activity_start')
          .setType('date')
          .setPlaceholder('MM/DD/YYYY')
          .setErrorMessage('Begin and end are swapped.')
          .useBuildCombo(() => true, useInput),
        username: new FilterItemBuilder()
          .setLabel('User Name')
          .setParamName('username')
          .setType('string')
          .useBuildCombo((v) => true, useInput),
        name: new FilterItemBuilder()
          .setLabel('Name')
          .setPermanent()
          .setParamName('name')
          .setType('string')
          .useBuildCombo((v) => true, useInput),
        /*last_name: new FilterItemBuilder()
        .setLabel('Last name')
        .setPermanent()
        .setParamName('last_name')
        .setType('string')
        .useBuildCombo((v) => true, useInput),*/
        email: new FilterItemBuilder()
          .setLabel('Email')
          .setPermanent()
          .setParamName('email')
          .setType('string')
          .useBuildCombo((v) => true, useInput),
        role: new FilterItemBuilder()
          .setLabel('Role')
          .setParamName('role')
          .select()
          .setOptions([
            { value: 'Administrator', label: 'Administrator' },
            { value: 'Super Coordinator', label: 'Super Coordinator' },
            { value: 'Coordinator', label: 'Coordinator' },
          ])
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        cbo_id: new FilterItemBuilder()
          .setLabel('CBO')
          .setParamName('cbo_id')
          .setPermanent()
          .select()
          .setReference()
          .setGetterFunc(getCBOs)
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        location_id: new FilterItemBuilder()
          .setLabel('Location')
          .setParamName('location_id')
          .setPermanent()
          .select()
          .setReference()
          .setGetterFunc(getAllLocations)
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        hide_legacy: new FilterItemBuilder()
          .setLabel('Hide Legacy')
          .setParamName('hide_legacy')
          .setPermanent()
          .checkbox()
          .useBuildCombo(useState),
        include_deactivated: new FilterItemBuilder()
          .setLabel('Also include deactivated users')
          .setParamName('include_deactivated')
          .setPermanent()
          .checkbox()
          // .startEnabled()
          .useBuildCombo(useState)
      };
    },
    ['name', 'email', 'cbo_id', 'location_id', 'hide_legacy', 'include_deactivated']
  );

  const cbo = filters.filterValues['cbo_id'].state.value
  // console.log(cbo)

  /**
   * Processes the user data.
   *
   * @param {User} user - The user data object.
   * @returns {any}
   */
  function userFormatter(user) {
    return {
      info_id: user.id,
      info_status: user.status,
      info_data: user,
      name: (
        <Grid container>
          <Grid item flexDirection={'column'}>
            <Typography color={'black'} fontSize={16}>
              {user.name}
            </Typography>
            <Typography color="#00000099" fontSize={14}>
              {user.role}
            </Typography>
          </Grid>
        </Grid>
      ),
      email: (
        <Grid container>
          <Grid item flexDirection={'column'}>
            <Typography color={'black'} fontSize={16}>
              {user.email}
            </Typography>
            <Typography color="#00000099" fontSize={14}>
              {user.id}
            </Typography>
          </Grid>
        </Grid>
      ),
      org: (
        <Grid container>
          <Grid item flexDirection={'column'}>
            <Typography color={'black'} fontSize={16}>
              {user.location_name || 'All Locations'}
            </Typography>
            <Typography color="#00000099" fontSize={14}>
              {user.cbo_name}
            </Typography>
          </Grid>
        </Grid>
      ),
      status: (
        <Chip
          label={user.status}
          sx={{ color: '#0000007A', textTransform: 'capitalize' }}
        />
      ),
      active_since: (user.active_since) && moment(user.active_since).format('MM/DD/YYYY'),
      last_login: (user.last_login) && moment(user.last_login).format('MM/DD/YYYY'),
    };
  }

  const [modalOpen, setModalOpen] = useState("none");
  const [data, setData] = useState(null);

  const {trigger: refreshTable, triggerValue: onRefreshTable} = useTrigger.parent();
  // const userLevel = useContext(CardEnrollmentUserLevelContext);
  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    add: addNotification,
    remove: removeNotification,
  } = useNotification();

  function closeModal() {
    setModalOpen('none');
    setData(null);
  }

  function openModal(type, data) {
    setModalOpen(type);
    setData(data);
  }

  const updateUserDetailsHandler = (prepareBody) => {
    removeNotification();

    updateUser({...prepareBody, id: data.id})
      .then(() => {
        closeModal();

        addNotification({
          status: "success",
          text: "The request has been completed",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
        refreshTable();
      })
      .catch((error) => {
        console.error(error);

        addNotification({
          status: "error",
          text: "Error saving user details. Please check your inputs and try again.",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
        refreshTable();
      });
  };

  const sendInvitationHandler = (body) => {
    removeNotification();
    userInvitation(body)
      .then((r) => {
        console.log('response', r)
        if (r.status === 'success') {
          addNotification({
            status: "success",
            text: "The request has been completed",
            btn_text: "OK",
          });

          setTimeout(() => {
            removeNotification();
          }, 3000);
          closeModal();
        } else {
          addNotification({
            status: "error",
            text: r.response.message,
            btn_text: "OK",
          });
        }
        refreshTable();
      })
      .catch((error) => {
        addNotification({
          status: "error",
          text: "Error sending. Please check your inputs and try again.",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
      });
  };

  function bulkDeactivate(userIds) {
    const userconf = userIds.map(ids => ids.trim()).join(', ').trim();
    if(!window.confirm(`Are you sure you want to deactivate ${userIds.length} users with usernames: ${userconf.substring(0, userconf.length - 1)}?`)) {
      return;
    }
    for(const id of userIds) {
      userActions(id, 'deactivate')
      .catch((error) => {
        console.error(error);

        if(!window.confirm(`Bulk action has failed. user with id, ${id} failed. Continue?`)) {
          return;
        }
      });
      refreshTable();
    }
  }

  /**
   * Preform an action with a user
   * @param {User} user the user that the action will be preformed with
   * @param {'resendInvitation' | 'resetPassword' | 'cancelInvitation' | 'deactivate', 'migrateUser'} action the action to be preformed with a user
   */
  function executeAction(user, action) {
    if(action === 'cancelInvitation') {
      if(!window.confirm(`Are you sure you want to cancel ${user.name}${user.name.endsWith('s') ? "'" : "'s"} invitation?`)) {
        return;
      }
    }
    if(action === 'deactivate') {
      if(!window.confirm(`Are you sure you want to deactivate ${user.name}?`)) {
        return;
      }
    }
    if(['resendInvitation', 'resetPassword', 'cancelInvitation', 'deactivate', 'reset_MFA'].includes(action)) {
      userActions(user.id, action)
      .then((response) => {
        addNotification({
          status: "success",
          text: response.message.toLowerCase() === 'ok' ? "Request preformed successfully" : response.message,
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
        refreshTable();
      })
      .catch((error) => {
        console.error(error);

        addNotification({
          status: "error",
          text: "Action has failed.",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
        refreshTable();
      });
    }
    if(action === 'migrateUser') {
      console.log(user)
      userInvitation({
        email: user.email,
        name: user.name,
        phone: user.phone_number,
        location_id: user.location_id,
        cbo_id: user.cbo_id,
        migrate: true,
        migration_id: user.id,
        role: ['Administrator', '', 'Coordinator', 'Super Coordinator'].indexOf(user.role) - 1
      }).then((r) => {
        if(r.status === 'success') {
          addNotification({
            status: "success",
            text: "User successfully migrated",
            btn_text: "OK",
          });
          refreshTable();
          return;
        }

        addNotification({
          status: "error",
          text: r.response.message,
          btn_text: "OK",
        });
        refreshTable();
      }).catch((e) => {
        console.error(e)
      })
    }
  }

  function renderRowActions(row) {
    const user = row.original;

    if (!user || !user.info_status) {
      return <></>;
    }

    return (
      <UserContextMenu
        user={user}
        executeAction={executeAction}
        openModal={openModal}
      />
    );
  }

  return (
    <>
      <GridPageTemplate
        name="Users"
        description="Manage your users across all organizations."
        filters={filters}
        fetchRequest={fetchUsers}
        fileName={'users'}
        mapping_function={userFormatter}
        button_text={'Search'}
        headerButtonProps={{
          btn_text: 'Invite User',
          onClick: () => openModal('invite', null)
        }}
        columns={[
          {
            accessorKey: 'name',
            header: 'Name / Role',
            size: 120,
          },
          {
            accessorKey: 'email',
            header: 'Email / Username',
            size: 150,
          },
          {
            accessorKey: 'org',
            header: 'Location / Organization',
            size: 145,
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 80,
          },
          {
            accessorKey: 'active_since',
            header: 'First Login',
            size: 85,
          },
          {
            accessorKey: 'last_login',
            header: 'Last Login',
            size: 85,
          },
        ]}
        rowActions={renderRowActions}
        // rowActionsSize={125}
        bulkActions={["Deactivate"]}
        onBulkAction={(action, selection) => {
          bulkDeactivate(Object.keys(selection))
        }}
        refreshTrigger={onRefreshTable}
      />

      {ReactDOM.createPortal((<>
        {modalOpen === 'edit' && <AdminInviteUserModal onClose={closeModal} onSave={updateUserDetailsHandler} data={data} isEditMode />}
        {modalOpen === 'view' && <AdminInviteUserModal onClose={closeModal} onSave={() => { }} data={data} readonly isEditMode />}
        {modalOpen === 'invite' && <AdminInviteUserModal onClose={closeModal} onSave={sendInvitationHandler} presetCbo={cbo} />}
      </>), document.body)}

      {notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={() => removeNotification()}
          />,
          document.querySelector("body")
        )}
    </>
  );
}

export default AdminUsersPage;
