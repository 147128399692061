import useInput from "../../hooks/useInput";
import is from "is_js";
import Modal from "../UI/Modal/Modal";
import Input from "../UI/Input/Input";
import Select from "../UI/Select/Select";
import { useEffect, useRef, useState } from "react";
import { getCBOs, getLocationsFromCBO } from "../../api/api";
import Checkbox from "../UI/Checkbox/Checkbox";
import classes from "./Users.module.scss";
import Button from "../UI/Button/Button";

/**
 * @typedef {Object} User
 * @property {number} id - The unique identifier of the user.
 * @property {string} name - The name of the user. Sanitized to remove HTML tags if necessary.
 * @property {number} location_id - The identifier for the user's location.
 * @property {string} location_name - The name of the user's location.
 * @property {number|null} cbo_id - The identifier for the user's community-based organization, null if not applicable.
 * @property {string|null} cbo_name - The name of the user's community-based organization, null if not applicable.
 * @property {string} email - The email address of the user.
 * @property {string} image - The URL of the user's thumbnail image.
 * @property {string} status - The status of the user.
 * @property {string} phone - The phone number of the user.
 * @property {number|null} active_since - The epoch time since the user has been active, null if not available.
 * @property {number|null} last_login - The epoch time of the user's last login, null if not available.
 * @property {boolean} file_upload_allowed - Indicates whether the user is allowed to upload files.
 * @property {string} role - The user's role in terms of card enrollment level.
 * @property {number} location_cbo_id - The cbo_id of the location, not the user.
 */

const roles = [
    {
        label: 'Coordinator',
        value: '1'
    },
    {
        label: 'Super Coordinator',
        value: '2'
    },
    {
        label: 'Administrator',
        value: '-1'
    }
]

const contacts = [
    {
        label: 'Do not replace',
        value: 'dnr'
    },
    {
        label: 'Primary Contact',
        value: 'pc'
    },
    {
        label: 'Secondary Contact',
        value: 'sc'
    },
]
/**
 *
 * @param {{onClose: any, onSave: any, isEditMode: boolean, data: User, readonly: boolean}} props
 * @returns
 */
const AdminInviteUserModal = ({ onClose, onSave, isEditMode, data, readonly, presetCbo }) => {
    const [locations, setLocations] = useState(null);
    const [CBOs, setCBOs] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    let defaultChoiceLocation = useRef(null);
    let defualtRole = useRef('1');

    /**
     * Role
     */
    const {
        value: role,
        hasError: roleHasError,
        inputChangeHandler: roleChangeHandler,
    } = useInput(value => true, isEditMode ? ({'Super Coordinator': '2', Coordinator: '1', Administrator: '-1'}[data.role]) : undefined)

    /**
     * CBO
     */
    const {
        value: cbo,
        hasError: cboHasError,
        inputChangeHandler: cboChangeHandler
    } = useInput(value => true, isEditMode ? data.cbo_id || data.location_cbo_id : presetCbo)

    useEffect(() => {
        if (CBOs !== null) {
            return;
        }
        getCBOs().then((cbos) => {
            const mapped = cbos.map(cbo => {
                return {
                    label: cbo.name,
                    value: cbo.id
                }
            })
            mapped.splice(0, 0, {
                label: 'Select a CBO',
                value: ''
            })
            setCBOs(mapped);
            updateLocations();
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CBOs])

    /**
     * Location
     */
    let {
        value: location,
        hasError: locationHasError,
        inputChangeHandler: locationChangeHandler
    } = useInput(value => true, isEditMode ? data.location_id : undefined)

    function updateLocations() {
        setLocations(null)
        getLocationsFromCBO(cbo).then((locs) => {
            if (locs === null) {
                return;
            }
            const mapped = locs.map(loc => {
                return {
                    label: loc.name,
                    value: loc.id,
                }
            })
            locationChangeHandler({target:{value: (data && data.location_id) || mapped[0].value || ''}})
            defaultChoiceLocation.current = mapped[0].value;
            setLocations(mapped)
        })
        
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(updateLocations, [cbo])

    /**
     * Name
     */
    const {
        value: name,
        isTouched: nameTouched,
        hasError: nameHasError,
        inputChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
    } = useInput(value => value !== '',  isEditMode ? data.name : undefined)

    /**
     * Email
     */
    const {
        value: email,
        isTouched: emailTouched,
        isValid: emailIsValid,
        hasError: emailHasError,
        inputChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
    } = useInput(value => is.email(value),  isEditMode ? data.email : undefined)

    /**
     * Location Contact
     */
    const {
        value: contact,
        hasError: contactHasError,
        inputChangeHandler: contactChangeHandler,
    } = useInput(value => true)

    const [allowFileUpload, setAllowFileUpload] = useState(data.file_upload_allowed === true);
    const fileUploadChangeHandler = (v) => {
        setAllowFileUpload(!allowFileUpload)
    }


    const saveHandler = (event) => {
        event.preventDefault();

        if (!emailIsValid) {
            emailBlurHandler();
            return;
        }


        /**
         * @type {import("../../api/users").UpdateUserRequestBody}
         */
        const prepareBody = {
            name: name,
            email: email,
            location_id: location || defaultChoiceLocation.current,
            location_contact: contact,
            upload_enabled: allowFileUpload,
            role: parseInt(role || defualtRole.current)
        };

        if(role === '2') {
            delete prepareBody.location_id;
            prepareBody.cbo_id = cbo;
        }

        setIsProcessing(true)
        onSave(prepareBody)
        setTimeout(() => setIsProcessing(false), 4000)
    }

    return (
        <Modal title={`${readonly ? 'View' : isEditMode ? 'Edit' : 'Invite New'} User (Admin)`} onClose={onClose}>
            <form onSubmit={saveHandler} className={classes.AdminModal}>
                <Select
                    label="Role:*"
                    options={roles}
                    alt={role}
                    clsType="small"
                    value={role}
                    onChange={roleChangeHandler}
                    hasError={roleHasError}
                    error="Choose a role."
                />
                <Select
                    label="CBO:*"
                    options={CBOs}
                    alt={cbo}
                    clsType="small"
                    value={cbo}
                    onChange={cboChangeHandler}
                    hasError={cboHasError}
                    error="Choose a CBO."
                />
                <div style={{display: role === '2' ? 'none' : 'block'}}>
                <Select
                    label="Location:*"
                    options={locations}
                    clsType="small"
                    disabled={locations === null || readonly}
                    value={location}
                    hasError={locationHasError}
                    onChange={locationChangeHandler}
                    errorMessage="Select Location"
                />
                </div>
                <Input
                    label="Name:*"
                    clsType="small"
                    required={true}
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                    value={name}
                    touched={nameTouched}
                    hasError={nameHasError}
                />

                <Input
                    label="Email:*"
                    clsType="small"
                    required={true}
                    onChange={emailChangeHandler}
                    onBlur={emailBlurHandler}
                    value={email}
                    touched={emailTouched}
                    hasError={emailHasError}
                    isValid={emailIsValid}
                />

                <Select
                    label="Location Contact:*"
                    options={contacts}
                    alt={contact}
                    clsType="small"
                    value={contact}
                    onChange={contactChangeHandler}
                    hasError={contactHasError}
                    error="Choose a role."
                />

                <Checkbox
                    label="Should be allowed to upload files"
                    value={allowFileUpload}
                    onChange={fileUploadChangeHandler}
                />
                {readonly ||
                <div className={classes.Modal__center}>
                    <Button color="primary" type="submit" disabled={isProcessing}>
                        {isEditMode ? "Update" : "Invite"}
                    </Button>
                </div>}
            </form>
        </Modal>
    );
};

export default AdminInviteUserModal;