import React from 'react';
import {
  useFilters,
} from '../components/List/Common/DynamicFilters/FilterStateBuilder.ts';
import GridPageTemplate from '../components/Reports/GridPageTemplate.js';
import { fetchCardTypesReport } from '../api/card.ts';
import moment from 'moment';
import { Typography } from '@mui/material';

const CardTypesPage = () => {
  const filters = useFilters(
    () => ({}), [], // empty filters
  );
  
  function formatter(card) {
    return {
      ...card,
      earliest_enrollment_date: moment(card.earliest_enrollment_date).format("MM/DD/YYYY"),
      name: (
        <>
          <span>{card.name}</span>
          <Typography color="#00000099" fontSize={14}>
            {card.id}
          </Typography>
        </>
      ),
      most_recent_enrollment_date: moment(card.most_recent_enrollment_date).format("MM/DD/YYYY"),
    }
  }
  
  return (
    <>
      <GridPageTemplate
        name="Card Types"
        description="Mapping cards in this system to US Bank"
        subtitle="REPORTS"
        filters={filters}
        fetchRequest={fetchCardTypesReport}
        fileName={'cardTypes'}
        mapping_function={formatter}
        columns={[
          {
            accessorKey: 'name',
            header: 'Card Type Name / ID',
          },
          {
            accessorKey: 'card_type',
            header: 'Card Type',
          },
          {
            accessorKey: 'program_id',
            header: 'Program',
          },
          {
            accessorKey: 'routing_number',
            header: 'Routing',
          },
          {
            accessorKey: 'earliest_enrollment_date',
            header: 'First Used',
          },
          {
            accessorKey: 'most_recent_enrollment_date',
            header: 'Last Used',
          },
        ]}
      />
    </>
  );
};

export default CardTypesPage;